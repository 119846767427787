* {
  box-sizing: border-box;
  --toastify-text-color-dark: #CBCFD4;
  --toastify-color-dark: #292F35;
}

h1 {
  font-family: 'Righteous';
  font-size: 64px;
}

h2 {
  font-family: 'Montserrat';
  font-size: 28px;
  line-height: 100%;
  font-weight: 700;
}

h3 {
  font-size: 32px;
  color: white;
}

h5 {
  font-family: 'Montserrat';
  font-size: 24px;
}

h4 {
  color: #F27A54;
  font-size: 10px;
}

/* Margin and padding reset */
h1, h2, h3, h4, h5, p {
  line-height: 100%;
  margin: 0;
}

p {
  font-family: 'Montserrat';
  color: #CBCFD4;
  font-size: 14px;
  line-height: 30px;
}